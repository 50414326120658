import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import "../Team/teammembers.css";
import Axios from "axios";
import {
  Button,
  Modal,
  Table,
  Form,
  Col,
  Row,
  FloatingLabel,
} from "react-bootstrap";
import swal from "sweetalert";

export default function Course() {
  const DataCourse = [
    {
      courses_code: 1,
      courses_title: "Course1",
      courses_detail: "Detail1",
      is_active: true,
    },
    {
      courses_code: 2,
      courses_title: "Course2",
      courses_detail: "Detail2",
      is_active: true,
    },
    {
      courses_code: 3,
      courses_title: "Course3",
      courses_detail: "Detail3",
      is_active: false,
    },
    {
      courses_code: 4,
      courses_title: "Course4",
      courses_detail: "Detail4",
      is_active: true,
    },
  ];
  return (
    <div className="TeamMembers">
      <Navbar />
      <div className="UserListHead">
        <p className="UserListTitle">Courses list</p>
      </div>
      <div className="TabbarUserList">
        <p className="TitleUserList">Find</p>
        <input
          className="searchInputUserList"
          placeholder="Search"
          type="search"
        />
        <Button
          variant="primary"
          className="fa fa-plu m-1"
          onClick={() => {
            alert("ยังไม่ใส่ปุ่ม");
          }}
        >
          Search
        </Button>
        <Button
          variant="success"
          className="fa fa-plu m-1"
          onClick={() => {
            alert("ยังไม่ใส่ปุ่ม");
          }}
        >
          Add New Courses
        </Button>
      </div>
      <div style={{ display: "flex" }}>
        <div className="DataGridUserList">
          <Table striped>
            <thead>
              <tr>
                <th>courses_code</th>
                <th>courses_title</th>
                <th>courses_detail</th>
              </tr>
            </thead>
            {DataCourse.map((item) => {
              if (item.is_active == "1")
                return (
                  <tbody>
                    <tr>
                      <td>{item.courses_code}</td>
                      <td>{item.courses_title}</td>
                      <td>{item.courses_detail}</td>

                      <td>
                        <Button size="sm" variant="warning">
                          Edit
                        </Button>
                      </td>
                      <td>
                        <Button size="sm" variant="danger" onClick={() => {}}>
                          Delete
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                );
            })}
          </Table>
        </div>
      </div>
    </div>
  );
}
