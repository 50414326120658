import Navbar from "../../ComponentUser/Narbar";
import Login from "../../ComponentUser/Login/Login";

export default function HomeUsers() {
  return (
    <div>
      <Login />
    </div>
  );
}
