import React from "react";
import Navbar from "../Navbar";
import "./quizs.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Axios from "axios";
import Book1 from "../../Img/Asset/book1.png";
import Book2 from "../../Img/Asset/book2.png";
export default function Quizs() {
  const [question, setQuestion] = useState([]);

  const DataQuiz = [
    {
      num: 1,
      title: "Eng1",
    },
    {
      num: 2,
      title: "Eng2",
    },
  ];

  const getQuestion = () => {
    Axios.get("https://flru.herokuapp.com/question")
      .then((res) => {
        setQuestion(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getQuestion();
  }, []);

  return (
    <div className="QuizsContainer">
      <Navbar />
      <div className="QuizsTitle">
        <p className="textQuizsTitle">Quiz</p>
      </div>

      <div className="tabQuizContainer">
        <Link to={"/Insertquiz"} style={{ textDecoration: "none" }}>
          <div className="AddQuizContainer">
            <img className="ImgQuizContainer" src={Book1} />
            <h3>Add Quiz</h3>
          </div>
        </Link>
        {question.map((item) => {
          return (
            <div className="EditQuizContainer">
              <div>
                <p className="BtnHead">EN</p>
              </div>
              <img className="ImgQuizContainer" src={Book2} />
              <p className="SkillsTitle">{item.quiz}</p>
              <Link to={"/EditQuiz"} style={{ textDecoration: "none" }}>
                <button className="BtnEditContainer">Edit</button>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}
