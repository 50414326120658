import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import "./Ranking.css";
import { Table, Button } from "react-bootstrap";
import swal from "sweetalert";
import Axios from "axios";

export default function Ranking() {
  const [toeic, setToeic] = useState([]);
  const [cefr, setCefr] = useState([]);
  const [ranks, setRanks] = useState();

  // const getToeic = () => {
  //   Axios.get("http://localhost:4000/rankcompares/edit-compares/TOEIC").then(
  //     (response) => {
  //       setToeic(response.data);
  //     }
  //   );
  // };

  // const getCefr = () => {
  //   Axios.get("http://localhost:4000/rankcompares/edit-compares/CEFR").then(
  //     (response) => {
  //       setCefr(response.data);
  //     }
  //   );
  // };

  const getRank = () => {
    Axios.get("https://flru.herokuapp.com/rankcompares").then((response) => {
      setRanks(response.data);
    });
  };

  useEffect(() => {
    getRank();
    // getToeic();
    // getCefr();
    console.log(ranks);
  }, []);

  return (
    <div className="RankingContainer">
      <Navbar />
      <div className="UserListHead">
        <p className="UserListTitle">User list</p>
      </div>
      <div className="TabbarUserList">
        <p className="TitleUserList">Find</p>
        <input
          className="searchInputUserList"
          placeholder="Search"
          type="search"
        />
        <Button
          variant="primary"
          className="fa fa-plu m-1"
          onClick={() => {
            swal({
              icon: "warning",
              title: `กำลังปรับปรุงฟังชั่นการใช้งาน`,
            });
          }}
        >
          Search
        </Button>
        <Button variant="success" className="fa fa-plu m-1" onClick={() => {}}>
          ADD NEW
        </Button>
      </div>
      <div style={{ display: "flex" }}>
        <div className="DataRankList">
          <Table striped>
            <thead>
              <tr>
                <th>TOEIC</th>
              </tr>
              <tr>
                <th>Rank</th>
                <th>MIN</th>
                <th>MAX</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {ranks?.[0].rank.map((item) => {
                return (
                  <tr>
                    <td>{item.name}</td>
                    <th>{item.min}</th>
                    <th>{item.max}</th>
                    <th>
                      <Button variant="warning">Edit</Button>
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className="DataRankList">
          <Table striped>
            <thead>
              <tr>
                <th>CEFR</th>
              </tr>
              <tr>
                <th>Rank</th>
                <th>MIN</th>
                <th>MAX</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {ranks?.[1].rank.map((item) => {
                return (
                  <tr>
                    <td>{item.name}</td>
                    <th>{item.min}</th>
                    <th>{item.max}</th>
                    <th>
                      <Button variant="warning">Edit</Button>
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}
