import React from "react";
import "./aboutus.css";

export default function AboutUS() {
  return (
    <div className="AllAboutUS">
      <div className="container-AboutUS">
        <section className="content-con">
          <div className="content-l">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/yc-748NaFos?autoplay=1&mute=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
              allowfullscreen
            ></iframe>
          </div>
          <div className="content-r">
            <h2>What is FLRU ?</h2>
            <p>
              Foreign Langauge Resources Unit (FLRU) is part of the School of
              Foreign Languages.at Suranaree University of Technology. It was
              established to promote teaching and development of foreign
              language skills
            </p>
          </div>
        </section>
        <section className="content-con">
          <div className="content-r">
            <h2>Our Goals</h2>
            <p>
              Through our resources, we hope to enable learners to learn foreign
              languages on their own, as well as to support the development of
              language skills for those who are interested. to apply knowledge
              effectively.
            </p>
          </div>
          <div className="content-ll">
            <img
              src="https://scontent.fnak3-1.fna.fbcdn.net/v/t1.15752-9/326864338_1541331706369333_5035405190554783178_n.png?_nc_cat=102&ccb=1-7&_nc_sid=ae9488&_nc_eui2=AeEXUYh0PvDrNlXWBR6Mv5d38kZ9EFzaRlLyRn0QXNpGUoy5jwEvRmqA2qObkO4jMlM09giQb6b8T_sYPtUE8VIK&_nc_ohc=vWSZKtZ1MNEAX9L91D4&_nc_ht=scontent.fnak3-1.fna&oh=03_AdTlmIFMHo780V99l43kSHBBjfWamjbzyiBXQ04sBIys8g&oe=63F5BB5A"
              alt=""
            />
          </div>
        </section>
      </div>
    </div>
  );
}
