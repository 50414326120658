import React from "react";
import { useState, useEffect } from "react";
import Navbar from "../Navbar";
import "./editquizs.css";
import Axios from "axios";
import { Button, Form, FloatingLabel } from "react-bootstrap";
export default function EditQuizs() {
  const [question, setQuestion] = useState([]);
  const [showADD, setShowADD] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const getQuestion = (id) => {
    Axios.get(`https://flru.herokuapp.com/question/edit-question/${id}`)
      .then((res) => {
        setQuestion(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getQuestion("63d77c96e3cd0ab358d75cf1");
    console.log(question);
  }, []);
  return (
    <div className="EditQuizsContainer">
      <Navbar />
      <div>
        <div className="QuizsTitle">
          <p className="textQuizsTitle">Edit Quiz</p>
        </div>
        <div className="EditAnswersContainer">
          {/* {question.map(
            ({ quiz, questions: [{ question, options }] }, index) => {
              return (
                <>
                  <div className="LitseningContainer">
                    {showEdit ? (
                      <>
                        <div className="ShowADDEditeQuiz">
                          <FloatingLabel
                            controlId="floatingTextarea"
                            label="Qusetion"
                            className="mb-3 mt-3"
                          >
                            <Form.Control
                              as="textarea"
                              placeholder="Leave a comment here"
                              value={quiz}
                            />
                          </FloatingLabel>
                          <Form.Floating className="mb-3">
                            <Form.Control
                              id="floatingInputCustom"
                              type="text"
                              placeholder="text"
                              value={options[0]}
                            />
                            <label htmlFor="floatingInputCustom">
                              options 1
                            </label>
                          </Form.Floating>
                          <Form.Floating className="mb-3">
                            <Form.Control
                              id="floatingInputCustom"
                              type="text"
                              placeholder="text"
                              value={options[1]}
                            />
                            <label htmlFor="floatingPasswordCustom">
                              options 2
                            </label>
                          </Form.Floating>
                          <Form.Floating className="mb-3">
                            <Form.Control
                              id="floatingInputCustom"
                              type="text"
                              placeholder="text"
                              value={options[2]}
                            />
                            <label htmlFor="floatingPasswordCustom">
                              options 3
                            </label>
                          </Form.Floating>
                          <Form.Floating className="mb-3">
                            <Form.Control
                              id="floatingInputCustom"
                              type="text"
                              placeholder="text"
                              value={options[3]}
                            />
                            <label htmlFor="floatingPasswordCustom">
                              options 4
                            </label>
                          </Form.Floating>
                          <Button
                            variant="secondary"
                            onClick={() => setShowEdit(false)}
                          >
                            Cancal
                          </Button>{" "}
                          <Button
                            variant="success"
                            onClick={() => setShowEdit(false)}
                          >
                            Edit
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="LitseningItem" key={index}>
                          <p className="EnglishSkillQuiz">
                            {quiz}{" "}
                            <Button
                              variant="warning"
                              onClick={() => setShowEdit(true)}
                            >
                              {" "}
                              Edit{" "}
                            </Button>
                          </p>
                          <div>
                            <p className="TabQuizs">{question}</p>
                            <div className="TabAnswers">
                              <input type="radio" value={options[0]} />
                              <label htmlFor="Bestie Wedding">
                                {options[0]}
                              </label>
                            </div>
                            <div className="TabAnswers">
                              <input type="radio" value={options[1]} />
                              <label htmlFor="Bestie Wedding">
                                {options[1]}
                              </label>
                            </div>
                            <div className="TabAnswers">
                              <input type="radio" value={options[2]} />
                              <label htmlFor="Bestie Wedding">
                                {options[2]}
                              </label>
                            </div>
                            <div className="TabAnswers">
                              <input type="radio" value={options[3]} />
                              <label htmlFor="Bestie Wedding">
                                {options[3]}
                              </label>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {showADD ? (
                      <>
                        <div className="ShowADDEditeQuiz">
                          <Button
                            variant="secondary"
                            onClick={() => setShowADD(false)}
                          >
                            Cancel
                          </Button>
                          <FloatingLabel
                            controlId="floatingTextarea"
                            label="Qusetion"
                            className="mb-3 mt-3"
                          >
                            <Form.Control
                              as="textarea"
                              placeholder="Leave a comment here"
                            />
                          </FloatingLabel>
                          <Form.Floating className="mb-3">
                            <Form.Control
                              id="floatingInputCustom"
                              type="text"
                              placeholder="text"
                            />
                            <label htmlFor="floatingInputCustom">options</label>
                          </Form.Floating>
                          <Form.Floating className="mb-3">
                            <Form.Control
                              id="floatingInputCustom"
                              type="text"
                              placeholder="text"
                            />
                            <label htmlFor="floatingPasswordCustom">
                              options
                            </label>
                          </Form.Floating>
                          <Form.Floating className="mb-3">
                            <Form.Control
                              id="floatingInputCustom"
                              type="text"
                              placeholder="text"
                            />
                            <label htmlFor="floatingPasswordCustom">
                              options
                            </label>
                          </Form.Floating>
                          <Form.Floating className="mb-3">
                            <Form.Control
                              id="floatingInputCustom"
                              type="text"
                              placeholder="text"
                            />
                            <label htmlFor="floatingPasswordCustom">
                              options
                            </label>
                          </Form.Floating>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="EditQuizADDItem"
                          onClick={() => setShowADD(true)}
                        >
                          <p>+ ADD Item</p>
                        </div>
                      </>
                    )}
                  </div>
                </>
              );
            }
          )} */}

          {/* {question.map(
            ({ quiz, questions: [{ question, options }] }, index) => {
              return (
                <>
                  <div className="LitseningContainer">
                    {showEdit ? (
                      <>
                        <div className="ShowADDEditeQuiz">
                          <FloatingLabel
                            controlId="floatingTextarea"
                            label="Qusetion"
                            className="mb-3 mt-3"
                          >
                            <Form.Control
                              as="textarea"
                              placeholder="Leave a comment here"
                              value={quiz}
                            />
                          </FloatingLabel>
                          <Form.Floating className="mb-3">
                            <Form.Control
                              id="floatingInputCustom"
                              type="text"
                              placeholder="text"
                              value={options[0]}
                            />
                            <label htmlFor="floatingInputCustom">
                              options 1
                            </label>
                          </Form.Floating>
                          <Form.Floating className="mb-3">
                            <Form.Control
                              id="floatingInputCustom"
                              type="text"
                              placeholder="text"
                              value={options[1]}
                            />
                            <label htmlFor="floatingPasswordCustom">
                              options 2
                            </label>
                          </Form.Floating>
                          <Form.Floating className="mb-3">
                            <Form.Control
                              id="floatingInputCustom"
                              type="text"
                              placeholder="text"
                              value={options[2]}
                            />
                            <label htmlFor="floatingPasswordCustom">
                              options 3
                            </label>
                          </Form.Floating>
                          <Form.Floating className="mb-3">
                            <Form.Control
                              id="floatingInputCustom"
                              type="text"
                              placeholder="text"
                              value={options[3]}
                            />
                            <label htmlFor="floatingPasswordCustom">
                              options 4
                            </label>
                          </Form.Floating>
                          <Button
                            variant="secondary"
                            onClick={() => setShowEdit(false)}
                          >
                            Cancal
                          </Button>{" "}
                          <Button
                            variant="success"
                            onClick={() => setShowEdit(false)}
                          >
                            Edit
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="LitseningItem" key={index}>
                          <p className="EnglishSkillQuiz">
                            {quiz}{" "}
                            <Button
                              variant="warning"
                              onClick={() => setShowEdit(true)}
                            >
                              {" "}
                              Edit{" "}
                            </Button>
                          </p>
                          <div>
                            <p className="TabQuizs">{question}</p>
                            <div className="TabAnswers">
                              <input type="radio" value={options[0]} />
                              <label htmlFor="Bestie Wedding">
                                {options[0]}
                              </label>
                            </div>
                            <div className="TabAnswers">
                              <input type="radio" value={options[1]} />
                              <label htmlFor="Bestie Wedding">
                                {options[1]}
                              </label>
                            </div>
                            <div className="TabAnswers">
                              <input type="radio" value={options[2]} />
                              <label htmlFor="Bestie Wedding">
                                {options[2]}
                              </label>
                            </div>
                            <div className="TabAnswers">
                              <input type="radio" value={options[3]} />
                              <label htmlFor="Bestie Wedding">
                                {options[3]}
                              </label>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {showADD ? (
                      <>
                        <div className="ShowADDEditeQuiz">
                          <Button
                            variant="secondary"
                            onClick={() => setShowADD(false)}
                          >
                            Cancel
                          </Button>
                          <FloatingLabel
                            controlId="floatingTextarea"
                            label="Qusetion"
                            className="mb-3 mt-3"
                          >
                            <Form.Control
                              as="textarea"
                              placeholder="Leave a comment here"
                            />
                          </FloatingLabel>
                          <Form.Floating className="mb-3">
                            <Form.Control
                              id="floatingInputCustom"
                              type="text"
                              placeholder="text"
                            />
                            <label htmlFor="floatingInputCustom">options</label>
                          </Form.Floating>
                          <Form.Floating className="mb-3">
                            <Form.Control
                              id="floatingInputCustom"
                              type="text"
                              placeholder="text"
                            />
                            <label htmlFor="floatingPasswordCustom">
                              options
                            </label>
                          </Form.Floating>
                          <Form.Floating className="mb-3">
                            <Form.Control
                              id="floatingInputCustom"
                              type="text"
                              placeholder="text"
                            />
                            <label htmlFor="floatingPasswordCustom">
                              options
                            </label>
                          </Form.Floating>
                          <Form.Floating className="mb-3">
                            <Form.Control
                              id="floatingInputCustom"
                              type="text"
                              placeholder="text"
                            />
                            <label htmlFor="floatingPasswordCustom">
                              options
                            </label>
                          </Form.Floating>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="EditQuizADDItem"
                          onClick={() => setShowADD(true)}
                        >
                          <p>+ ADD Item</p>
                        </div>
                      </>
                    )}
                  </div>
                </>
              );
            }
          )} */}
          <div className="LitseningContainer">
            {question.questions?.map((item, index) => {
              return (
                <>
                  <h1>{item.question}</h1>
                  {item.options.map((item, index) => {
                    return (
                      <p>
                        {index + 1}
                        {item}
                      </p>
                    );
                  })}
                </>
              );
            })}
          </div>
          <div className="TabContainer">
            <p className="SectionText">Section</p>
            <button className="BtnCRUDAdd">Add Section</button>
            <button className="BtnCRUDSave">Save Quiz</button>
          </div>
        </div>
      </div>
    </div>
  );
}
