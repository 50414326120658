import Navbar from "../../ComponentUser/Narbar";
import AllQuiz from "../../ComponentUser/Quiz/AllQuiz";

export default function HomeUsers() {
  return (
    <div>
      <Navbar />
      <AllQuiz />
    </div>
  );
}
