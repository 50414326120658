import React from 'react'
import Navbar from '../Navbar'
import './SingIn_outs.css'
import { DataGrid } from '@mui/x-data-grid';

export default function SingIn_outs() {

  const columns = [
    { field: 'id', headerName: 'No.', width: 90 },
    { field: 'stdid', headerName: 'StudentID', width: 95 },
    {
      field: 'fullName',
      headerName: 'Name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 200,
      valueGetter: (params) =>
        `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
    { field: 'subject', headerName: 'Subject', width: 145 },
    { field: 'group', headerName: 'Group', width: 145 },
    { field: 'singIn', headerName: 'SingIn', width: 145 },
    { field: 'singOut', headerName: 'SingOut', width: 145 },

  ];

  const rows = [
    { id: 1, lastName: 'Snow', firstName: 'Jon', group: 35, subject: "Software Developer", stdid: "B6316532" ,singIn:'12.21.51',singOut:'-'},
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', group: 42, subject: "Software Developer", stdid: "B6316532",singIn:'-',singOut:'13.01.24' },
    { id: 3, lastName: 'Lannister', firstName: 'Jaime', group: 45, subject: "Software Developer", stdid: "B6316532",singIn:'12.21.51',singOut:'-' },
    { id: 4, lastName: 'Stark', firstName: 'Arya', group: 16, subject: "Software Developer", stdid: "B6316532",singIn:'-',singOut:'13.01.24' },
    { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', group: null, subject: "Software Developer", stdid: "B6316532",singIn:'12.21.51',singOut:'-' },
    { id: 6, lastName: 'Melisandre', firstName: 'Array', group: 150, subject: "Software Developer", stdid: "B6316532",singIn:'12.21.51',singOut:'13.01.24' },
    { id: 7, lastName: 'Clifford', firstName: 'Ferrara', group: 44, subject: "Software Developer", stdid: "B6316532",singIn:'12.21.51',singOut:'13.01.24' },
    { id: 8, lastName: 'Frances', firstName: 'Rossini', group: 36, subject: "Software Developer", stdid: "B6316532",singIn:'12.21.51',singOut:'13.01.24' },
    { id: 9, lastName: 'Roxie', firstName: 'Harvey', group: 65, subject: "Software Developer", stdid: "B6316532",singIn:'12.21.51',singOut:'13.01.24' },
    { id: 10, lastName: 'Snow', firstName: 'Jon', group: 35, subject: "Software Developer", stdid: "B6316532",singIn:'12.21.51',singOut:'13.01.24' },
    { id: 11, lastName: 'Lannister', firstName: 'Cersei', group: 42, subject: "Software Developer", stdid: "B6316532",singIn:'12.21.51',singOut:'13.01.24' },
    { id: 12, lastName: 'Lannister', firstName: 'Jaime', group: 45, subject: "Software Developer", stdid: "B6316532",singIn:'12.21.51',singOut:'13.01.24' },
    { id: 13, lastName: 'Stark', firstName: 'Arya', group: 16, subject: "Software Developer", stdid: "B6316532",singIn:'12.21.51',singOut:'13.01.24' },
    { id: 14, lastName: 'Targaryen', firstName: 'Daenerys', group: null, subject: "Software Developer", stdid: "B6316532",singIn:'12.21.51',singOut:'13.01.24' },
    { id: 15, lastName: 'Melisandre', firstName: 'Array', group: 150, subject: "Software Developer", stdid: "B6316532",singIn:'12.21.51',singOut:'13.01.24' },
    { id: 16, lastName: 'Clifford', firstName: 'Ferrara', group: 44, subject: "Software Developer", stdid: "B6316532",singIn:'12.21.51',singOut:'13.01.24' },
    { id: 17, lastName: 'Frances', firstName: 'Rossini', group: 36, subject: "Software Developer", stdid: "B6316532",singIn:'12.21.51',singOut:'13.01.24' },
    { id: 18, lastName: 'Roxie', firstName: 'Harvey', group: 65, subject: "Software Developer", stdid: "B6316532",singIn:'12.21.51',singOut:'13.01.24' },
  ];

  return (
    <div className='SingInOutContainer1'>
        <Navbar/>
        <div className='UserListHead'>
        <p className='UserListTitle' >SingIn & SingOut</p>
      </div>
      <div className='SingContainer'>
        <div className='SingTime'>
            <p className='SizeDay'>19 October 2022</p>
            <p className='SizeTime'>11 : 54 :08</p>
        </div>
        <div className='SingCode'>
            <p className='headBarcode'>SingIn & Out</p>
            <p className='TitleBarcode'>Barcode <input className='InputBarcode' placeholder='Search' type="search"/></p>
            <div style={{display:'flex'}}>
            <button className='BtnConfirm'>Confirm</button>
            </div>
        </div>
        <div>
        
        </div>
      </div>
      <div style={{ height: '42vh', width: '80%' }} className="DataGridSingInOut">
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
          />
        </div>
    </div>
  )
}