import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import "./teammembers.css";
import Axios from "axios";
import {
  Button,
  Modal,
  Table,
  Form,
  Col,
  Row,
  FloatingLabel,
} from "react-bootstrap";
import swal from "sweetalert";

export default function TeamMembers() {
  const [users, setUsers] = useState([]);
  const [RowUsers, setRowUsers] = useState([]);
  const [ViewShow, setViewShow] = useState(false);
  const handleViewShow = () => {
    setViewShow(true);
  };
  const handleViewClose = () => {
    setViewShow(false);
  };

  ///for Edit Model
  const [EditShow, setEditShow] = useState(false);
  const handleEditShow = () => {
    setEditShow(true);
  };
  const handleEditClose = () => {
    setEditShow(false);
  };

  ///for Delete Model

  const [DeleteShow, setDeleteShow] = useState(false);
  const handleDeleteShow = () => {
    setDeleteShow(true);
  };
  const handleDeleteClose = () => {
    setDeleteShow(false);
  };

  ///for Add Model
  const [PostShow, setPostShow] = useState(false);
  const handlePostShow = () => {
    setPostShow(true);
  };
  const handlePostClose = () => {
    setPostShow(false);
  };

  //// Search BTN
  const [Search, setSearch] = useState("");

  ///Define here local state that store the form data
  const [user_id, setUser_id] = useState("");
  const [password, setPassword] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [status, setStatus] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [created_at, setCreated_at] = useState();
  const [updated_at, setUpdated_at] = useState();

  // const getUsers = () => {
  //   Axios.get("http://localhost:3001/users").then((response) => {
  //     setUsers(response.data);
  //   });
  // };

  const getUsers = () => {
    Axios.get("http://localhost:4000/users").then((response) => {
      setUsers(response.data);
    });
  };

  useEffect(() => {
    getUsers();
    console.log(users);
  }, []);

  const handleSubmite = async () => {
    await Axios.post("http://localhost:3001/create", {
      user_id: user_id,
      password: password,
      firstname: firstname,
      lastname: lastname,
      status: status,
      email: email,
      tel: tel,
      created_at: created_at,
      updated_at: updated_at,
    }).then(() => {
      setUsers([
        ...users,
        {
          user_id: user_id,
          password: password,
          firstname: firstname,
          lastname: lastname,
          status: status,
          email: email,
          tel: tel,
          created_at: created_at,
          updated_at: updated_at,
        },
      ]);
    });
    window.location.reload();
  };

  const handleEdit = (id) => {
    // alert(
    //   "id:" +
    //     user_id +
    //     "   :" +
    //     password +
    //     "   :" +
    //     firstname +
    //     "   :" +
    //     lastname +
    //     "   :" +
    //     status +
    //     "   :" +
    //     email +
    //     "   :" +
    //     tel +
    //     "   :" +
    //     created_at +
    //     "   :" +
    //     updated_at
    // );
    Axios.put(`http://localhost:3001/update`, {
      user_id: user_id,
      password: password,
      firstname: firstname,
    }).then((response) => {
      setUsers(
        users.map((val) => {
          return val.user_id == id
            ? {
                password: val.password,
                firstname: val.firstname,
              }
            : val;
        })
      );
    });
    window.location.reload();
  };

  const handleDelete = async (id, userid) => {
    await swal({
      title: "Are you sure?",
      text: `${userid} deleted , you will not be able to recover!`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Axios.delete(`http://localhost:4000/users/delete-users/${id}`).then(
          async (res) => {
            await swal(`Your ${userid} has been deleted!`, {
              icon: "success",
            });
            window.location.reload();
          }
        );
      } else {
        swal("You Undelete");
      }
    });
  };

  const [showADD, setShowADD] = useState(false);

  const handleADDClose = () => setShowADD(false);
  const handleADDShow = () => setShowADD(true);

  const handleADDSubmit = async () => {
    const dataRegister = [
      {
        user_id: user_id,
        password: password,
        firstname: firstname,
        lastname: lastname,
        status: status,
        email: email,
        tel: tel,
      },
    ];

    console.log(dataRegister);

    if (user_id) {
      Axios.post("http://localhost:4000/users/create-users", dataRegister).then(
        async (res) => {
          await swal({
            icon: "success",
            title: `SIGN UP ${user_id}`,
            text: `Thank you, ${firstname}  ${lastname} for applying for membership.`,
          });
          console.log(res.data);
          setShowADD(false);
          window.location.reload();
        }
      );
    } else {
      await swal({
        icon: "warning",
        title: `SIGN UP Error`,
        text: `Please confirm the conditions for applying for membership.`,
      });
    }
  };

  return (
    <div className="TeamMembers">
      <Navbar />
      <div className="UserListHead">
        <p className="UserListTitle">User list</p>
      </div>
      <div className="TabbarUserList">
        <p className="TitleUserList">Find</p>
        <input
          className="searchInputUserList"
          placeholder="Search"
          type="search"
        />
        <Button
          variant="primary"
          className="fa fa-plu m-1"
          onClick={() => {
            swal({
              icon: "warning",
              title: `กำลังปรับปรุงฟังชั่นการใช้งาน`,
            });
          }}
        >
          Search
        </Button>
        <Button
          variant="success"
          className="fa fa-plu m-1"
          onClick={() => {
            handleADDShow();
          }}
        >
          Add New Users
        </Button>
      </div>
      <div style={{ display: "flex" }}>
        <div className="DataGridUserList">
          <Table striped>
            <thead>
              <tr>
                <th>No.</th>
                <th>FirstName</th>
                <th>LastName</th>
                <th>Status</th>
                <th>Email</th>
                <th>Tel</th>
              </tr>
            </thead>
            {users.map((item) => {
              return (
                <tbody>
                  <tr>
                    <td>{item.user_id}</td>
                    <td>{item.firstname}</td>
                    <td>{item.lastname}</td>
                    <td>{item.status}</td>
                    <td>{item.email}</td>
                    <td>{item.tel}</td>
                    <td>
                      <Button size="sm" variant="warning">
                        Edit
                      </Button>
                    </td>
                    <td>
                      <Button
                        size="sm"
                        variant="danger"
                        onClick={() => {
                          handleDelete(item._id, item.user_id);
                        }}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </Table>
        </div>
      </div>

      {/* Model for ADD User */}
      <div className="model-box-view">
        <Modal show={showADD} onHide={handleADDClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>ADD USER</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="g-2 mb-3">
                <Col md>
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      placeholder="UserID"
                      onChange={(e) => setUser_id(e.target.value)}
                    />
                    <label htmlFor="floatingPasswordCustom">UserID</label>
                  </Form.Floating>
                </Col>
                <Col md>
                  <Form.Floating>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label htmlFor="floatingPasswordCustom">Password</label>
                  </Form.Floating>
                </Col>
              </Row>
              <Row className="g-2 mb-3">
                <Col md>
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      placeholder="Firstname"
                      onChange={(e) => setFirstname(e.target.value)}
                    />
                    <label htmlFor="floatingPasswordCustom">Firstname</label>
                  </Form.Floating>
                </Col>
                <Col md>
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      placeholder="Lastname"
                      onChange={(e) => setLastname(e.target.value)}
                    />
                    <label htmlFor="floatingPasswordCustom">Lastname</label>
                  </Form.Floating>
                </Col>
                <Col md>
                  <FloatingLabel
                    controlId="floatingSelectGrid"
                    label="Works with selects"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option>Open this Select Status</option>
                      <option value="student">Student</option>
                      <option value="staff">Staff</option>
                      <option value="teacher">Teacher</option>
                      <option value="admin">Admin</option>
                    </Form.Select>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="g-2">
                <Col md>
                  <Form.Floating>
                    <Form.Control
                      type="email"
                      placeholder="Email Addres"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="floatingPasswordCustom">Email Addres</label>
                  </Form.Floating>
                </Col>
                <Col md>
                  <Form.Floating>
                    <Form.Control
                      type="tel"
                      placeholder="Tel"
                      onChange={(e) => setTel(e.target.value)}
                    />
                    <label htmlFor="floatingPasswordCustom">Tel</label>
                  </Form.Floating>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleADDClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleADDSubmit}>
              ADD Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      {/* Model for View */}
      <div className="model-box-view">
        <Modal
          show={ViewShow}
          onHide={handleViewClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>View Users</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  value={RowUsers.user_id}
                  readOnly
                />
              </div>
              <div className="form-group mt-3">
                <input
                  type="text"
                  className="form-control"
                  value={RowUsers.firstname}
                  readOnly
                />
              </div>
              <div className="form-group mt-3">
                <input
                  type="text"
                  className="form-control"
                  value={RowUsers.lastname}
                  readOnly
                />
              </div>
              <div className="form-group mt-3">
                <input
                  type="text"
                  className="form-control"
                  value={RowUsers.status}
                  readOnly
                />
              </div>
              <div className="form-group mt-3">
                <input
                  type="email"
                  className="form-control"
                  value={RowUsers.email}
                  readOnly
                />
              </div>
              <div className="form-group mt-3">
                <input
                  type="tel"
                  className="form-control"
                  value={RowUsers.tel}
                  readOnly
                />
              </div>
              <div className="form-group mt-3">
                <input
                  type="datetime-local"
                  className="form-control"
                  value={RowUsers.created_at}
                  readOnly
                />
              </div>
              <div className="form-group mt-3">
                <input
                  type="date"
                  className="form-control"
                  value={RowUsers.updated_at}
                  readOnly
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleViewClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* Model for Add */}
      {/* <div className="model-box-view">
        <Modal
          show={PostShow}
          onHide={handlePostClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Users</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setUser_id(e.target.value)}
                  placeholder="Please Enter STD_ID"
                />
              </div>
              <div className="form-group mt-3">
                <input
                  type="password"
                  className="form-control"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Please Enter Password"
                />
              </div>
              <div className="form-group mt-3">
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setFirstname(e.target.value)}
                  placeholder="Please Enter Firstname"
                />
              </div>
              <div className="form-group mt-3">
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setLastname(e.target.value)}
                  placeholder="Please Enter Lastname"
                />
              </div>
              <div className="form-group mt-3">
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setStatus(e.target.value)}
                  placeholder="Please Enter Status"
                />
              </div>
              <div className="form-group mt-3">
                <input
                  type="email"
                  className="form-control"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Please Enter Email"
                />
              </div>
              <div className="form-group mt-3">
                <input
                  type="tel"
                  className="form-control"
                  onChange={(e) => setTel(e.target.value)}
                  placeholder="Please Enter Tel"
                />
              </div>
              <div className="form-group mt-3">
                <input
                  type="datetime-local"
                  className="form-control"
                  onChange={(e) => setCreated_at(e.target.value)}
                  placeholder="Please Enter Created_at"
                />
              </div>
              <div className="form-group mt-3">
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) => setUpdated_at(e.target.value)}
                  placeholder="Please Enter Updated_at"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              className="btn btn-success mt-4"
              onClick={handleSubmite}
            >
              ADD User
            </Button>
            <Button
              variant="secondary"
              className="btn btn-secondary mt-4"
              onClick={handlePostClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div> */}

      {/* Model for Edit */}
      <div className="model-box-view">
        <Modal
          show={EditShow}
          onHide={handleEditClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Users</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="form-group">
                <label>UserID</label>
                <input
                  type="text"
                  className="form-control"
                  value={RowUsers.user_id}
                />
              </div>
              <div className="form-group mt-3">
                <label>Password</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Please Enter Password"
                  defaultValue={RowUsers.password}
                />
              </div>
              <div className="form-group mt-3">
                <label>Firstname</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setFirstname(e.target.value)}
                  placeholder="Please Enter Firstname"
                  defaultValue={RowUsers.firstname}
                />
              </div>
              <div className="form-group mt-3">
                <label>Lastname</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setLastname(e.target.value)}
                  placeholder="Please Enter Lastname"
                  defaultValue={RowUsers.lastname}
                />
              </div>
              <div className="form-group mt-3">
                <label>Status</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setStatus(e.target.value)}
                  placeholder="Please Enter Status"
                  defaultValue={RowUsers.status}
                />
              </div>
              <div className="form-group mt-3">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Please Enter Email"
                  defaultValue={RowUsers.email}
                />
              </div>
              <div className="form-group mt-3">
                <label>Tel</label>
                <input
                  type="tel"
                  className="form-control"
                  onChange={(e) => setTel(e.target.value)}
                  placeholder="Please Enter Tel"
                  defaultValue={RowUsers.tel}
                />
              </div>
              <div className="form-group mt-3">
                <label>Created_at</label>
                <input
                  type="datetime-local"
                  className="form-control"
                  onChange={(e) => setCreated_at(e.target.value)}
                  placeholder="Please Enter Created_at"
                  defaultValue={RowUsers.created_at}
                />
              </div>
              <div className="form-group mt-3">
                <label>Updated_at</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Please Enter Updated_at"
                  defaultValue={RowUsers.updated_at}
                  onChange={(e) => setUpdated_at(e.target.value)}
                />
              </div>
              <Button
                type="submit"
                className="btn btn-success mt-4"
                onClick={() => handleEdit(RowUsers.user_id)}
              >
                Edit User
              </Button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleEditClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
