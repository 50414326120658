import "./HomeUser.css";
import quiz from "../../Img/Asset/quiz.png";
import Button from "react-bootstrap/Button";
import Cook from "../../Img/Asset/cook.png";
import Book1 from "../../Img/Asset/book1.png";
import Book2 from "../../Img/Asset/book2.png";
import Card from "react-bootstrap/Card";
import Calendar from "../../Img/Asset/calendar.png";
import Carousel from "react-bootstrap/Carousel";

import Banner1 from "../../Img/Poster_banner/banner/แบนเนอร์กิจกรรม.png";
import Banner2 from "../../Img/Poster_banner/banner/แบนเนอร์แนะนำมินิคอร์ส.png";
import Banner3 from "../../Img/Poster_banner/banner/แบนเนอร์กิจกรรม2.png";

export default function HomeUser() {
  const services = [
    {
      title: "Mini Courses",
      btn: "Book Now",
      img: { Calendar },
    },
    {
      title: "English Skill Test",
      btn: "Task Quiz",
      img: { Book1 },
    },
  ];

  return (
    <>
      <div className="HomeUser">
        <Carousel variant="dark">
          <Carousel.Item>
            <img className="d-block w-100" src={Banner1} alt="First slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={Banner2} alt="Second slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={Banner3} alt="Third slide" />
          </Carousel.Item>
        </Carousel>
      </div>
      {/* <div className="bg-color">
        <div className="banner-head">
          <div className="container">
            <div className="banner-con">
              <div className="title1">
                <div className="title-r">
                  <img src={Cook} alt="" />
                </div>
                <div className="title-l">
                  <h2>Easy Access</h2>
                  <p>
                    Students can view information about the mini-courses they
                    are interested in by using the mini-course booking system.
                    Check seat availability and book your seat right away.
                  </p>
                </div>
              </div>
              <div className="title1">
                <div className="title-r">
                  <img src={Book2} alt="" />
                </div>
                <div className="title-l">
                  <h2>Upskill your English</h2>
                  <p>
                    Our English Skill test analyzes a variety of skills and
                    introduces mini-courses to help reinforce weak areas.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="container-title">
        <section className="content-con">
          <div className="content-r">
            <h2>About us</h2>
            <p>
              Foreign Langauge Resources Unit (FLRU) is part of the School of
              Foreign Languages.at Suranaree University of Technology. It was
              established to....
            </p>
            <Button variant="warning">Learn more</Button>
          </div>
          <div className="content-l">
            <img src={quiz} alt="" />
          </div>
        </section>
      </div>
      <div className="BG-Services">
        <div className="container-title">
          <div className="Services-body">
            <h1>Our Services</h1>
            <div className="ItemServices">
              {services.map((item) => {
                return (
                  <Card
                    style={{
                      width: "16rem",
                      marginLeft: "50px",
                      marginRight: "50px",
                    }}
                  >
                    <Card.Img variant="top" src={Calendar} />
                    <Card.Body>
                      <Card.Title>{item.title}</Card.Title>
                      <Button variant="danger">{item.btn}</Button>
                    </Card.Body>
                  </Card>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="footer-title">
          <h2>Foreign Language Resource Unit</h2>
          <p>
            SUT Sport and Health Center Floor 1 Suranaree University of
            Technology 111 Maha Witthayalai Rd, Suranaree Sub-district, Muaeng
            district, Nakhon Ratchasima 30000
          </p>
        </div>
        <div className="footer-links">
          <h2>Quick Links</h2>
          <p>Home</p>
          <p>English Skill Test</p>
          <p>Mini course</p>
          <p>About us</p>
        </div>
        <div className="footer-contact">
          <h2>Contact US</h2>
          <span class="material-icons-outlined">call</span>
          <label>044-22-4656</label>
          <span class="material-icons-outlined">home</span>
          <label>Foreign Languages Resource Unit (FLRU)</label>
        </div>
      </div>
    </>
  );
}
