import Navbar from "../../ComponentUser/Narbar";
import Rank from "../../ComponentUser/Rank/Rank";

export default function Rarking() {
  return (
    <div>
      <Navbar />
      <Rank />
    </div>
  );
}
