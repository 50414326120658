import Navbar from "../../ComponentUser/Narbar";
import Quiz from "../../ComponentUser/Quiz/QuizUser";

export default function QuizUser() {
  return (
    <div>
      <Navbar />
      <Quiz />
    </div>
  );
}
