import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./startQuiz.css";
import Axios from "axios";
import BGrank from "../../Img/Asset/BG-rank.png";
import swal from "sweetalert";

export default function StartQuiz() {
  const [question, setQuestion] = useState([]);

  const [rankType, setRankType] = useState();

  const getQuestion = (id) => {
    Axios.get(`https://flru.herokuapp.com/question/edit-question/${id}`).then(
      (response) => {
        setQuestion(response.data);
      }
    );
  };

  useEffect(() => {
    getQuestion("63df989c6ccf33c0f7126366");
    console.log(question?.questions?.[currentQuestion].is_right);
  }, []);

  const DataAnswers1 = [
    {
      question: "______they were tired, they worked overtime.",
      type: "Listening",
      answerOptions: [
        { qa_id: 1, choice: "Because", is_right: "0" },
        { qa_id: 2, choice: "In spite", is_right: "0" },
        { qa_id: 3, choice: "Since", is_right: "0" },
        { qa_id: 4, choice: "Although", is_right: "1" },
      ],
    },
    {
      question:
        "Neither Ms. Chen _____ Mr. Martinez was able to attend the seminar.",
      type: "Listening",
      answerOptions: [
        { qa_id: 5, choice: "nor", is_right: "1" },
        { qa_id: 6, choice: "and", is_right: "0" },
        { qa_id: 7, choice: "neither", is_right: "0" },
        { qa_id: 8, choice: "or", is_right: "0" },
      ],
    },
    {
      question:
        "________Mr. Park worked for us, he had received training abroad.",
      type: "Listening",
      answerOptions: [
        { qa_id: 9, choice: "Before", is_right: "1" },
        { qa_id: 10, choice: "After", is_right: "0" },
        { qa_id: 11, choice: "While", is_right: "0" },
        { qa_id: 12, choice: "When", is_right: "0" },
      ],
    },
    {
      question: "Please answer the phone _____ it rings.",
      type: "Listening",
      answerOptions: [
        { qa_id: 13, choice: "during", is_right: "0" },
        { qa_id: 14, choice: "when", is_right: "1" },
        { qa_id: 15, choice: "because", is_right: "0" },
        { qa_id: 16, choice: "and", is_right: "0" },
      ],
    },
    {
      question:
        "You can look at the clothes un the shop windows,________ you can’t go in and buy them.",
      type: "Listening",
      answerOptions: [
        { qa_id: 17, choice: "but", is_right: "0" },
        { qa_id: 18, choice: "or", is_right: "1" },
        { qa_id: 19, choice: "after", is_right: "0" },
        { qa_id: 20, choice: "while", is_right: "0" },
      ],
    },
  ];

  const DataAnswers = [
    {
      question: "______they were tired, they worked overtime.",
      type: "Listening",
      answerOptions: [
        { qa_id: 1, choice: "Because", is_right: "0" },
        { qa_id: 2, choice: "In spite", is_right: "0" },
        { qa_id: 3, choice: "Since", is_right: "0" },
        { qa_id: 4, choice: "Although", is_right: "1" },
      ],
    },
    {
      question:
        "Neither Ms. Chen _____ Mr. Martinez was able to attend the seminar.",
      type: "Listening",
      answerOptions: [
        { qa_id: 5, choice: "nor", is_right: "1" },
        { qa_id: 6, choice: "and", is_right: "0" },
        { qa_id: 7, choice: "neither", is_right: "0" },
        { qa_id: 8, choice: "or", is_right: "0" },
      ],
    },
    {
      question:
        "________Mr. Park worked for us, he had received training abroad.",
      type: "Listening",
      answerOptions: [
        { qa_id: 9, choice: "Before", is_right: "1" },
        { qa_id: 10, choice: "After", is_right: "0" },
        { qa_id: 11, choice: "While", is_right: "0" },
        { qa_id: 12, choice: "When", is_right: "0" },
      ],
    },
    {
      question: "Please answer the phone _____ it rings.",
      type: "Listening",
      answerOptions: [
        { qa_id: 13, choice: "during", is_right: "0" },
        { qa_id: 14, choice: "when", is_right: "1" },
        { qa_id: 15, choice: "because", is_right: "0" },
        { qa_id: 16, choice: "and", is_right: "0" },
      ],
    },
  ];

  const EngRank = [
    {
      title: "CEFR",
      rank: "A1",
      score: 1,
    },
    {
      title: "CEFR",
      rank: "A2",
      score: 2,
    },
    {
      title: "CEFR",
      rank: "A3",
      score: 3,
    },
    {
      title: "CEFR",
      rank: "A4",
      score: 4,
    },
    {
      title: "CEFR",
      rank: "A5",
      score: 5,
    },
  ];

  const [currentQuestion, setCurrentQuestion] = useState(0);

  const [showScore, setShowScore] = useState(false);

  const [score, setScore] = useState(0);

  const [scoreListening, setScoreListening] = useState(0);
  const [scoreSpeaking, setScoreSpeaking] = useState(0);
  const [scoreWriting, setScoreWriting] = useState(0);
  const [scoreReading, setScoreReading] = useState(0);

  const handleAnswerOptionClick = async (is_right) => {
    if (is_right === "1") {
      // alert("this answer is correct!");
      setScore(score + 1);
      setScoreListening(scoreListening + 1);
    }

    const nextQuestion = currentQuestion + 1;

    if (nextQuestion < DataAnswers.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      await swal({
        icon: "success",
        title: `Score ${score} / ${DataAnswers.length} `,
      });
      setShowScore(true);
    }
  };

  const OptionClick = async (is_right) => {
    const right = await question?.questions?.[currentQuestion].is_right;
    try {
      if (is_right == right) {
        // setCurrentQuestion(scoreListening + 1);
        setScore(score + 1);
        console.log("ถูก:" + score + " " + is_right);
      } else {
        console.log("ผิด:" + score);
      }

      const nextQuestion = currentQuestion + 1;

      if (nextQuestion < question?.questions?.length) {
        setCurrentQuestion(nextQuestion);
      } else {
        await swal({
          icon: "success",
          title: `Score ${score} / ${question?.questions?.length} `,
        });
        setShowScore(true);
      }
    } catch (error) {
      console.log(error);
    }

    console.log("ท้าย:" + score);
  };

  return (
    <div className="QuizUser">
      {showScore ? (
        <>
          <div className="contalner-StartQuiz">
            <div className="StartQuiz-con">
              <div className="QuizResult">
                <h1 className="QuizResultTitle">Quiz Result</h1>
                <div className="BtnTypeChart">
                  <Button variant="warning">Overall</Button>
                  <Button variant="light">Chart</Button>
                </div>
                <div className="ViewFull">
                  {EngRank.map((item) => {
                    if (item.title == "CEFR") {
                      if (item.score === score) {
                        return (
                          <>
                            <div className="ViewAsScore">
                              <p>{item.title}</p>
                              <h1>{item.rank}</h1>
                            </div>
                          </>
                        );
                      }
                    }
                  })}

                  <div className="OverallScore">
                    <p>Overall Score</p>
                    <h1>
                      {score}/{question?.questions?.length}
                    </h1>
                  </div>
                  <div className="TypeScore">
                    <p>Listening</p>
                    <ProgressBar
                      now={scoreListening}
                      label={scoreListening}
                      max={question?.questions?.length}
                    />
                    <p>Speaking</p>
                    <ProgressBar
                      now={scoreSpeaking}
                      label={scoreSpeaking}
                      max={question?.questions?.length}
                    />
                    <p>Reading</p>
                    <ProgressBar
                      now={scoreWriting}
                      label={scoreWriting}
                      max={question?.questions?.length}
                    />
                    <p>Writing</p>
                    <ProgressBar
                      now={scoreReading}
                      label={scoreReading}
                      max={question?.questions?.length}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Suggestion">
            <h2>Suggestion</h2>
            <div className="SuggestionBody">
              <h4>Listening</h4>
              <p>
                มินต์วอล์คไฮไลท์ ซูมแมคเคอเรลกับดักเรตโฟม สเปกรุสโซคูลเลอร์
                สวีทเอสเพรสโซดยุคไลฟ์ คอร์รัปชั่นแอสเตอร์ระโงกคลิป
                สถาปัตย์ราเม็งดีกรี มั้ยแคนูโปรเจ็คชัตเตอร์ โบรชัวร์บูติก
              </p>
            </div>
          </div>
          <div className="RecommendedCourse">
            <h2>Recommended Course</h2>
            <div className="ViewSchedule">
              <div className="head-color"></div>
              <p>TOEIC-Listening</p>
              <p>TBA</p>
              <p>Prof.A</p>
              <img src={BGrank} alt="" />
              <Button variant="primary">View Schedule</Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="contalner-StartQuiz">
            <div className="StartQuiz-con">
              <div className="StartQuizBody">
                <div className="next-Quiz">
                  <p>
                    Question {currentQuestion + 1} /{" "}
                    {question?.questions?.length}
                  </p>
                </div>
                {/* <div className="Quiz-Part">
                  <p id="p1">PART I - Listening</p>
                  <h2>{DataAnswers[currentQuestion].question}</h2>
                  <div className="BtnAnswer">
                    {DataAnswers[currentQuestion].answerOptions.map(
                      (answerOption) => (
                        <button
                          onClick={() =>
                            handleAnswerOptionClick(answerOption.is_right)
                          }
                        >
                          {answerOption.choice}
                        </button>
                      )
                    )}
                  </div>
                </div> */}

                <div className="Quiz-Part">
                  <h2>{question?.questions?.[currentQuestion].question}</h2>
                  <p id="p1">
                    PART I - {question?.questions?.[currentQuestion].type}
                  </p>
                  <div className="BtnAnswer">
                    {question?.questions?.[currentQuestion].options.map(
                      (item, index) => {
                        return (
                          <>
                            <button
                              key={index}
                              onClick={() => OptionClick(item)}
                            >
                              {item}
                            </button>
                          </>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
