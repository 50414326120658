import Navbar from "../../ComponentUser/Narbar";
import HomeUser from "../../ComponentUser/HomeUser/HomeUser";

export default function HomeUsers() {
  return (
    <div>
      <Navbar />
      <HomeUser />
    </div>
  );
}
