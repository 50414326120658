import React, { useState } from "react";
import Logo from "../Img/Logo.png";
import "./narbar.css";

export default function Narbar() {
  const MeunData = [
    {
      title: "Home",
      url: "/",
      cName: "nav-links",
      icon: "fa-solid fa-house-user",
    },
    {
      title: "Quiz",
      url: "/AllQuizs",
      cName: "nav-links",
      icon: "fa-solid fa-circle-info",
    },
    {
      title: "Mini Courses",
      url: "Ranking",
      cName: "nav-links",
      icon: "fa-solid fa-briefcase",
    },
    {
      title: "About us",
      url: "/AboutUS",
      cName: "nav-links",
      icon: "fa-solid fa-address-book",
    },
    {
      title: "Log In",
      url: "/home",
      cName: "nav-links-In",
    },
    {
      title: "Sign Up",
      url: "/Login",
      cName: "nav-links-mobile",
    },
  ];

  const [click, setClick] = useState(false);
  const handleClick = () => {
    setClick((curr) => !curr);
  };

  return (
    <nav className="NavbarItem">
      <h1 className="logo">
        {/* React <i className="fab fa-react"></i> */}
        <img src={Logo} width="60" height="60" />
      </h1>
      <div className="menu-icons" onClick={handleClick}>
        <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
      </div>
      <ul className={click ? "nav-menu active" : "nav-menu"}>
        {MeunData.map((item, index) => {
          return (
            <li key={index}>
              <a href={item.url} className={item.cName}>
                <i className={item.icon}></i>
                {item.title}
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
