import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "./QuizUser.css";
import Axios from "axios";

export default function QuizUser() {
  const [question, setQuestion] = useState([{}]);

  const QuizData = [
    {
      quiz: "English Skills",
      content:
        "The English Skills Test assesses listening, reading, grammar, andvocabulary skills with instructions on how to practice and developthe necessary skills.",
      Total: 40,
      score_max: 100,
      end_time: 25,
    },
  ];

  const getQuestion = (id) => {
    Axios.get(`https://flru.herokuapp.com/question/edit-question/${id}`).then(
      (response) => {
        setQuestion(response.data);
      }
    );
  };

  useEffect(() => {
    getQuestion("63d54084aae99741b9579881");
    console.log(question);
  }, []);

  return (
    <div className="QuizUser">
      <div className="container-AllQuiz">
        <div className="Emg-con">
          {QuizData.map((item) => {
            return (
              <>
                <div className="contentEng-r">
                  <h1>{question.quiz}</h1>
                  <p>{question.content}</p>
                  <p>
                    Total : {question.totol} Items ({question.score_max} Marks)
                  </p>
                  <p>Time in Total: {question.end_time} minutes</p>
                  <Button
                    variant="primary"
                    className="fa fa-plu m-1"
                    href="/StartQuizs"
                  >
                    Start Quiz
                  </Button>
                  <Button variant="warning" className="fa fa-plu m-1" href="#">
                    Continue
                  </Button>
                </div>
              </>
            );
          })}

          <div className="contentEng-l">
            <img
              src="https://scontent.fnak3-1.fna.fbcdn.net/v/t1.15752-9/320219469_5909887665767914_2218661379947959959_n.png?_nc_cat=104&ccb=1-7&_nc_sid=ae9488&_nc_eui2=AeEso5JnZDGeQfEaYdiUxwunuh8GpjhUfCK6HwamOFR8IqVotuJw1g1VAuq5Us3r-gVDeWiWjsEiesANmDqfhDcT&_nc_ohc=MIEWZmg1vFcAX-qlmTJ&_nc_ht=scontent.fnak3-1.fna&oh=03_AdQIueYxa1V5dhY7H3hXzqxsH770xmIVrL52aF-59gCD3g&oe=63E2E5B0"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
