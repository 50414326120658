import Navbar from "../../ComponentUser/Narbar";
import Aboutus from "../../ComponentUser/AboutUS/AboutUS";

export default function AboutUS() {
  return (
    <div>
      <Navbar />
      <Aboutus />
    </div>
  );
}
