import { useState, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import "./allQuiz.css";
import Axios from "axios";

export default function AllQuiz() {
  const [question, setQuestion] = useState([]);

  // const QuizDataNum = [
  //   {
  //     num: 1,
  //     title: "EnglishSkills1",
  //   },
  //   {
  //     num: 2,
  //     title: "EnglishSkills2",
  //   },
  //   {
  //     num: 3,
  //     title: "EnglishSkills3",
  //   },
  // ];

  const getQuestion = () => {
    Axios.get("https://flru.herokuapp.com/question")
      .then((res) => {
        setQuestion(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getQuestion();
    console.log(question);
  }, []);

  return (
    <div className="AllQuiz">
      <div className="container-AllQuiz">
        <p className="tab-allquiz">Home &raquo; Quiz</p>
        <div className="QuizFull">
          <h1>Quiz</h1>
          <div className="Btn-Type">
            <Button className="btn btn-warning ">All</Button>
            <Button className="btn btn-warning ">English</Button>
          </div>
          {question.map((item) => {
            return (
              <div className="Card-Quiz">
                <Card style={{ width: "12rem" }}>
                  <img
                    variant="top"
                    src="https://scontent.fnak3-1.fna.fbcdn.net/v/t1.15752-9/321434432_1196606964574943_1838620908463595022_n.png?_nc_cat=108&ccb=1-7&_nc_sid=ae9488&_nc_eui2=AeG_WhYuZgGTLR0IU2l8dUPfSkOqDO5xHpZKQ6oM7nEelj9meff5fEpM3tw9IhnJ3E0hQnMOIuROTXfcFmtugwMQ&_nc_ohc=qZ3wNHbu0coAX_nd40k&_nc_ht=scontent.fnak3-1.fna&oh=03_AdR_Pvk-21qINgcuXkwCFQOmuayo1mog3IRwZuDdMprb2A&oe=63E32514"
                  />
                  <Card.Body>
                    <Card.Title>{item.quiz}</Card.Title>
                    <Card.Text>{item.type}</Card.Text>
                    <Button variant="danger" href="/QuizUser">
                      View Quiz
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
