import Navbar from "../../ComponentUser/Narbar";
import StartQuiz from "../../ComponentUser/Quiz/StartQuiz";

export default function QuizUser() {
  return (
    <div>
      <Navbar />
      <StartQuiz />
    </div>
  );
}
